import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiUrl } from "../../axios";
import { Catalogue } from "../Parts/Catalogue/Catalogue";

export const DataContainer = ({ URI, isMain }) => {
    const params = useParams()
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        apiUrl
            .get(URI, { params })
            .then((res) => {
                setData(res.data)
                setLoading(false)
                console.log(res)
            })
            .then(() => console.log(data))
            .catch((err) => { console.log(err) })
    }, [])

    return (
        <>
                <Catalogue loading={loading} isMain={isMain} data={data} />
        </>
    )
}